import moment from 'moment';
import { noop } from 'lodash';
import { CustomInput } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../../core/form/components/Listview';

const AdminBenchmarkGroupsLsv = ({ rows, getSubProbes = noop, onClick = noop, handleChange = noop }) => {
  const renderRows = (headers, record) => {
    const extraClass = '';

    const top20ProbeCount = record?.assocs.filter(assoc => assoc?.column.tag === 'benchmark_top20').length;
    const grpAvgProbeCount = record?.assocs.filter(assoc => assoc?.column.tag === 'benchmark_1yr_average').length;

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'period': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>{record.period.description}</td>
          );
        }
        case 'group': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>{record.group.name}</td>
          );
        }
        case 'benchmark_top20': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>{record.benchmark_top20?.data?.toFixed(2)}%</td>
          );
        }
        case 'benchmark_top20_probes': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {top20ProbeCount}
              <Icon
                name="chart-line"
                title="Sub Probes"
                className="ml-2 text-primary"
                onClick={() => getSubProbes('benchmark_top20', record)}
                style={{cursor: 'pointer'}}
              />
            </td>
          );
        }
        case 'benchmark_1yr_average': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>{record.benchmark_1yr_average?.data?.toFixed(2)}%</td>
          );
        }
        case 'benchmark_1yr_average_probes': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {grpAvgProbeCount}
              <Icon
                name="chart-line"
                title="Sub Probes"
                className="ml-2 text-primary"
                onClick={() => getSubProbes('benchmark_1yr_average', record)}
                style={{cursor: 'pointer'}}
              />
            </td>
          );
        }
        case 'updated': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>{moment(record.updated).format('YYYY-MM-DD HH:MM')}</td>
          );
        }
        case 'is_published': {
          return (
            <td key={index} className={classes} style={styles}>
              <CustomInput
                type="switch"
                role="switch"
                id={record.id}
                checked={record?.is_published}
                value={record?.is_published}
                onChange={(event) => handleChange(event, record)}
                className=""
                style={{ cursor: 'pointer'}}
              />
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes + extraClass} data-sort-value={record[sortColumn]} style={styles}>
              {record[field] && record[field].name ? record[field].name : record[field]}
            </td>
          );
      }

    });

    return tableTd;
  };

  const iconName = 'timer';
  const emptyCaption = 'No Benchmark Groups/Period found';

  const upperHeaders = [
    { caption: null, cells: 2, classes: 'border-bottom-0' },
    {
      caption: 'Top 20%',
      cells: 2,
      classes: 'text-center bg-pink-60 border border-lightgray',
    },
    {
      caption: '1yr Average',
      cells: 2,
      classes: 'text-center bg-orange-60 border border-lightgray',
    },
    { caption: null, cells: 2, classes: 'border-bottom-0' },
  ];

  const headers = [
    { caption: 'Benchmark Group', field: 'group', classes: 'text-left' },
    { caption: 'Period', field: 'period', classes: 'text-left', styles: {width: '150px'} },
    { caption: 'ROA', field: 'benchmark_top20', classes: 'text-right bg-pink-10', styles: {width: '120px'} },
    { caption: 'Probes', field: 'benchmark_top20_probes', classes: 'text-right bg-pink-10', styles: {width: '120px'} },
    { caption: 'ROA', field: 'benchmark_1yr_average', classes: 'text-right bg-orange-10', styles: {width: '120px'} },
    { caption: 'Probes', field: 'benchmark_1yr_average_probes', classes: 'text-right bg-orange-10', styles: {width: '120px'} },
    { caption: 'Updated', field: 'updated', classes: 'text-center', styles: {width: '130px'} },
    { caption: 'Published', field: 'is_published', classes: 'text-center', styles: {width: '100px'} },
  ];

  const upperHeadTh = upperHeaders.map(({ caption, cells, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));
  
  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} >
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      upperHeadTh={upperHeadTh}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AdminBenchmarkGroupsLsv;
