
import React from 'react';
import { connect } from 'react-redux';
import { Container, Label, Table, UncontrolledPopover, PopoverBody } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';

import { fetchAnalysisGroupMembers } from 'jsx/components/manage/actions/analysis_group_members';

import { BenchmarkDataRow } from '../../components/benchmarking/BenchmarkDataRow';
import { BenchmarkSection } from '../../components/benchmarking/BenchmarkSection';
import { BenchmarkChart } from '../../components/benchmarking/BenchmarkChart';

import { fetchProbeTemplateByTag } from '../../actions/admin/probe';
import { fetchWarehouseProperty, fetchWarehouseGroup, fetchWarehouseGroupAssocs } from '../../actions/warehouse';

import { controls } from '../../forms/benchmarking/benchmarks';

const addHeight = (text) => (
  <div className="d-flex justify-content-center">
    <span className="mb-1">{text}</span>
  </div>
);

const getPopover = (message, target) => (
  <UncontrolledPopover placement="right" target={target} trigger="hover">
    <PopoverBody>
      <p className="p-0 m-0">{message}</p>
    </PopoverBody>
  </UncontrolledPopover>
);

class Benchmark extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      benchmarkControls: controls,
      period_id: null,
      property_id: null,
      isChartOpen: false,
      chartData: [],
      chartTitle: '',
      chartExtra: {}
    };

    this.runReport = this.runReport.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.getSubProbes = this.getSubProbes.bind(this);
    this.setChartModal = this.setChartModal.bind(this);
  }

  async componentDidMount() {
    const { benchmarkControls } = this.state;
    const { userProbes } = this.props.warehouse;

    // Fetch Groups enabled for property
    const groups = await this.props.dispatch(fetchAnalysisGroupMembers({property_id: this.props.property_id}));
    benchmarkControls.group_id.options = groups.rows.map(row => ({id: row?.group?.id, name: row?.group?.name}));

    if (groups?.rows?.length > 0) {
      benchmarkControls.group_id.value = groups.rows[0].group.id;
    }

    // Only load combo in portal
    if (this.props.showProbeSelect) {
      benchmarkControls.probe_id.options = userProbes.map(probe => ({id: probe?.id, name: `${probe.property.name} - ${probe?.period_rolling_description}`}));
      benchmarkControls.probe_id.value = this.props.probe_id;
    }

    // Fetch probe template
    this.props.dispatch(fetchProbeTemplateByTag('standard_probe', this.props.property_id));

    this.setState({period_id: this.props.period_id, property_id: this.props.property_id}, () => {
      this.runReport();
    });
  }

  setChartModal(isChartOpen) {
    this.setState({isChartOpen});
  }

  async getSubProbes(row, probePropertyRow, probeGroupRow, column_tag) {
    const subProbes = await this.props.dispatch(fetchWarehouseGroupAssocs(probeGroupRow?.id, column_tag));

    const chartData = subProbes.map(subProbe => {
      return {
        name: subProbe?.metric?.property?.name,
        // value: subProbe?.metric?.client_entered?.data?.value,
        value: subProbe?.metric?.client_benchmark_values?.data?.value,
      };
    });

    const chartExtra = {};
    chartExtra.value = `${probePropertyRow?.client_benchmark_values?.data?.value.toFixed(2)}${row?.metric?.unit_attributes?.suffix}`;
    chartExtra.rawValue = probePropertyRow?.client_benchmark_values?.data?.value;
    // chartExtra.value = `${probePropertyRow?.client_entered?.data?.value.toFixed(2)}${row?.metric?.unit_attributes?.suffix}`;
    chartExtra.unit_attributes = row?.metric?.unit_attributes;
    chartExtra.metric_name = row?.metric?.name;
    chartExtra.column_tag = column_tag;
    chartExtra.period_description = this.props.period_description;
    chartExtra.property_name = this.props.property_name;

    const chartTitle = `${chartExtra.metric_name} (${chartExtra.value}) - ${column_tag}`;

    this.setState({chartData, chartTitle, chartExtra}, () => {
      this.setChartModal(true);
    });
  }

  async runReport() {
    const { benchmarkControls, period_id, property_id } = this.state;

    // Check and set group
    const selectedGroupId = benchmarkControls.group_id.value;

    // Get warehouse property values
    await this.props.dispatch(fetchWarehouseProperty(period_id, property_id));

    // Get analysis group values
    await this.props.dispatch(fetchWarehouseGroup(period_id, selectedGroupId));
    this.setState({validationMessage: null});
  }

  onHandleChange(fieldName, value) {
    const { benchmarkControls } = this.state;
    const { userProbes } = this.props.warehouse;

    benchmarkControls[fieldName].value = value;

    const probe = userProbes.find(userProbe => userProbe.id === benchmarkControls.probe_id.value);
    this.setState({benchmarkControls, period_id: probe?.period_id, property_id: probe?.property_id}, () => this.runReport());
  }

  renderListviewRows (section, isGroupPublished) {
    const { probeRows, probeGroups } = this.props.warehouse;
    const lsvRows = [];
    let prevCaption;

    section.groups.map(group => {
      group.rows.map(row => {
        const lsvRow = {
          group_caption: (prevCaption !== group.caption ? group.caption : '')
        };
        lsvRow.id = row.id;
        lsvRow.metric_name = row.metric.name;
        lsvRow.metric_key = row.metric.key;
        lsvRow.formula = row.metric.formula;
        lsvRow.description = row.metric.description;
        lsvRow.unit = row.metric.unit_attributes.suffix;
        lsvRow.attributes = row.attributes;
        lsvRow.show_benchmarks = row.show_benchmarks;

        const results = [];
        const probePropertyRow = probeRows.find(probeRow => probeRow.metric_id === row.metric.id && probeRow.kpigroup_id === group.id);
        const gaugeFn = () => {};

        const prefix = probePropertyRow?.client_entered?.unit_attributes.prefix || '';
        const suffix = probePropertyRow?.client_entered?.unit_attributes.suffix || '';

        const options = {maximumFractionDigits: 2};
        if (probePropertyRow) {
          results.push({iconFn: gaugeFn, value: `${prefix}${probePropertyRow?.client_entered?.data?.value?.toLocaleString(undefined, options) || 0}${suffix}`});
          results.push({iconFn: gaugeFn, value: `${prefix}${probePropertyRow?.client_benchmark_values?.data?.value?.toLocaleString(undefined, options) || 0}${suffix}`});
          results.push({value: false});
        }

        const probeGroupRow = probeGroups.find(probeGroup => probeGroup.metric_id === row.metric.id && probeGroup.kpigroup_id === group.id);
        let benchmark_top20 = ( probeGroupRow?.benchmark_top20?.data ? `${prefix}${probeGroupRow?.benchmark_top20?.data?.toLocaleString(undefined, options)}${suffix}` : false );
        let benchmark_1yr_average = (probeGroupRow?.benchmark_1yr_average?.data ? `${prefix}${probeGroupRow?.benchmark_1yr_average?.data?.toLocaleString(undefined, options)}${suffix}` : false);
        
        if (!isGroupPublished && !this.props.showSubProbesIcon) {
          benchmark_top20= false;
          benchmark_1yr_average= false;
        }

        results.push({
          iconFn: () => this.getSubProbes(row, probePropertyRow, probeGroupRow, 'benchmark_1yr_average'), 
          value: benchmark_1yr_average 
        });
        results.push({
          iconFn: () => this.getSubProbes(row, probePropertyRow, probeGroupRow, 'benchmark_top20'), 
          value: benchmark_top20
        });
        results.push({value: false});

        lsvRows.push(
          <BenchmarkDataRow
            key={lsvRow.id}
            categoryName={lsvRow.group_caption}
            metricName={lsvRow.metric_name}
            results={results}
            showSubProbesIcon={this.props.showSubProbesIcon}
          />
        );
        prevCaption = group.caption;
      });
    });

    return lsvRows;
  };

  renderSections(isGroupPublished) {
    const { probeTemplate } = this.props.probe;

    if (probeTemplate.sections) {
      const sections = probeTemplate.sections.map((section, index) => (
        <React.Fragment key={index}>
          <BenchmarkSection  name={section.caption} colour="green-20" />
          {this.renderListviewRows(section, isGroupPublished)}
        </React.Fragment>
      ));
  
      return sections;
    }

    return (<></>);
  }

  render() {
    const { benchmarkControls, validationMessage, isChartOpen, chartData, chartTitle, chartExtra} = this.state;
    const { probeRows, probeGroups } = this.props.warehouse;

    const probeRow = probeRows?.find(row => row.client_entered.key === 'return_on_assets');
    const probeUpdated = (probeRow ? moment(probeRow.updated).format('YYYY-MM-DD HH:mm') : '-');

    let groupMessage = 'This Benchmark Group have not been calculated yet';
    let isGroupPublished = false;
    let benchMarkUpdated = '-';

    if (probeGroups?.length > 0) {
      const probeGroup = probeGroups?.find(row => row.metric.key === 'return_on_assets');
      console.log("pgrobe", probeGroup)
      if (probeGroup) {
        groupMessage = (probeGroup.is_published ? '' : 'This Benchmark Group has not yet been published');
        isGroupPublished = probeGroup.is_published;
        benchMarkUpdated = moment(probeGroup.updated).format('YYYY-MM-DD HH:mm');
      }
    }

    return (
      <Container fluid className="h-100">
        <div className="d-flex justify-content-start bg-light border border-gray-20 rounded p-0 mb-1">
          { this.props.showProbeSelect && (
            <>
              <Label className="ml-3" style={{marginTop: 10}}>Probe</Label>
              <FormInputSelect className="m-1" hideCaption handleChange={(event) => this.onHandleChange('probe_id', event.value)} control={benchmarkControls.probe_id} />
            </>
          )}
        
          <Label className="ml-3" style={{marginTop: 10}}>Benchmark Group</Label>
          <FormInputSelect className="m-1" hideCaption handleChange={(event) => this.onHandleChange('group_id', event.value)} control={benchmarkControls.group_id} />
          <small className="ml-3 text-danger" style={{marginTop: 14}}>{groupMessage}</small>
        </div>

        {validationMessage && (
          <h4 className="text-center m-3 mt-4 text-danger">{validationMessage}</h4>
        )}

        {!validationMessage && probeRows.length > 0 && (
          <>
            <BenchmarkChart
              setModal={this.setChartModal}
              isOpen={isChartOpen}
              chartData={chartData}
              chartTitle={chartTitle}
              chartExtra={chartExtra}
            />
            <Table hover bordered responsive>
              <thead className="text-center border-top">
                <tr className="">
                  <th colSpan="2" className="border-bottom-0" style={{ fontSize: '14px' }}></th>
                  <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                    Your Result
                  </th>
                  <th colSpan="3" className="border-bottom-0" style={{ fontSize: '14px' }}>
                    Benchmark Group Results
                  </th>
                </tr>
                <tr className="border-top">
                  <th></th>
                  <th>{addHeight('Metric')}</th>
                  <th className="bg-yellow-20">
                    Using<br/>Client Values
                    <Icon
                      id="ClientValuesInfo"
                      as="button"
                      name="info-circle"
                      title="Client Values Information"
                      className="ml-1 p-0"
                    />
                    {getPopover('Based on the values you entered in the stockflows and cropflows', 'ClientValuesInfo')}
                  </th>
                  <th className="bg-pink-20">Using<br/>Benchmark Values</th>
                  <th className="bg-gray-20 text-secondary text-nowrap">
                    3 Year Average
                    <Icon
                      id="BenchmarkValuesInfo"
                      as="button"
                      name="info-circle"
                      title="Client Values Information"
                      className="ml-1 p-0"
                    />
                    {getPopover('Using Benchmark Values', 'BenchmarkValuesInfo')}
                  </th>
                  <th className="bg-blue-20">{addHeight('Group Average')}</th>
                  <th className="bg-blue-20">{addHeight('Top 20%')}</th>
                  <th className="bg-gray-20 text-secondary">Top 20%<br/>3 Year Average</th>
                </tr>
              </thead>
              <tbody>
                {this.renderSections(isGroupPublished)}
              </tbody>
            </Table>
            <small>Probe Updated: <span className='font-bolder'>{probeUpdated}</span> - Benchmark Group Updated: <span className='font-bolder'>{benchMarkUpdated}</span></small>
          </>
        )}
      </Container>
    );
  };
}

const mapStoreToProps = ({ realm, probe, warehouse, enterprises, analysis_groups }) => ({
  realm,
  probe,
  warehouse,
  enterprises,
  analysis_groups
});

export default connect(mapStoreToProps)(Benchmark);
