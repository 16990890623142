/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { Alert, Col, Label, Row } from 'reactstrap';

import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';
import { fetchAnalysisPeriods } from 'jsx/components/manage/actions/analysis_periods';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import FormBase from 'jsx/components/core/form/components/FormBase';
import ReactSelect from 'react-select';
import SearchToolbar from 'jsx/components/core/toolbars/SearchToolbar';
import { fetchAdminAdvisors } from 'jsx/components/manage/actions/admin';
import { controls as controlsTemplate } from '../../../manage/forms/property_analysis';
import {
  fetchPropertyAnalysis,
  setPropertyAnalysisParams,
  setPropertyAnalysisSelectedAdvisor,
  setPropertyAnalysisSelectedAnalysisPeriod,
  setPropertyAnalysisSelectedRCSStatus,
} from '../actions/properties';

import { fetchAttributes } from '../../portrait/actions/attributes';

class PropertyAnalysis extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      selectedAdvisor: null,
      advisorOptions: [],
      rcsStatusOptions: [],
      searchValue: '',
    };
  }

  async componentDidMount() {
    const { params } = this.props.properties;
    console.log("params", params.analysis_period_id)
  
    // Fetch global analysis periods
    const analysisPeriods = await this.props.dispatch(fetchAnalysisPeriods());

    if (analysisPeriods?.count === 0) return;

    const analysisPeriodOptions = analysisPeriods.rows.map((period) => ({
      value: period.id,
      label: period.description,
    }));

    let [selectedAnalysisPeriod] = analysisPeriodOptions;
    if (params.analysis_period_id) {
      selectedAnalysisPeriod = analysisPeriodOptions.find(option => option.value === params.analysis_period_id);
    }

    await this.setPropertyAnalysisParams(
      'analysis_period_id',
      selectedAnalysisPeriod?.value ?? null,
    );
    await this.props.dispatch(setPropertyAnalysisSelectedAnalysisPeriod(selectedAnalysisPeriod));

    // Setup advisor options
    const advisors = await this.props.dispatch(fetchAdminAdvisors());
    const advisorOptions = advisors.rows.map((advisor) => ({
      value: advisor.id,
      label: advisor.name,
    }));

    // Setup RCS Status options
    const rcsStatuses = await this.props.dispatch(
      fetchAttributes({ type: 'analysis_period_rcs_statuses' }),
    );
    const rcsStatusOptions = rcsStatuses.rows.map((status) => ({
      value: status.id,
      label: status.name,
    }));

    this.setState({
      analysisPeriodOptions,
      advisorOptions,
      rcsStatusOptions,
    });

    // Fetch Property Analysis data
    this.refresh();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.refresh && this.props.refresh) this.refresh();
  };

  addQuarterControls = (controls, quarters = []) => {
    const updatedControls = cloneDeep(controls);

    if (isEmpty(quarters)) {
      updatedControls.q1 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q2 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q3 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q4 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      return updatedControls;
    }

    quarters.forEach((quarter, index) => {
      const { value, caption } = quarter;
      const quarterIndex = index + 1;
      const controlName = `q${quarterIndex}`;
      updatedControls[controlName] = {
        ...controlDefaults,
        value,
        fieldName: `quarters.${index.toString()}.value`,
        caption,
        classesFromRowPath: `quarters.${index.toString()}.rowClasses`,
        listviewOrder: quarterIndex,
        showInListview: true,
      };
    });
    return updatedControls;
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onSearchChange();
  };

  onAlertDismiss = () =>
    this.props.dispatch({ type: 'SET_PROPERTY_RESPONSE_MESSAGE', payload: null });

  onAdvisorChange = async (selectedAdvisor) => {
    await this.props.dispatch(setPropertyAnalysisSelectedAdvisor(selectedAdvisor));
    await this.setPropertyAnalysisParams('advisor_id', selectedAdvisor?.value ?? null);
    this.refresh();
  };

  onAnalysisPeriodChange = async (selectedAnalysisPeriod) => {
    await this.props.dispatch(setPropertyAnalysisSelectedAnalysisPeriod(selectedAnalysisPeriod));
    await this.setPropertyAnalysisParams(
      'analysis_period_id',
      selectedAnalysisPeriod?.value ?? null,
    );
    this.refresh();
  };

  onRCSStatusChange = async (selectedRCSStatus) => {
    await this.props.dispatch(setPropertyAnalysisSelectedRCSStatus(selectedRCSStatus));
    await this.setPropertyAnalysisParams('rcs_status_id', selectedRCSStatus?.value ?? null);
    this.refresh();
  };

  onEditProperty = (id) => this.props.history.push(`/home/portrait/property/${id}`);

  onSearchChange = async () => {
    const { searchValue } = this.state;
    await this.setPropertyAnalysisParams('search_value', searchValue);
    this.refresh();
  };

  refresh = () => {
    const { selectedAnalysisPeriod, selectedAdvisor, selectedRCSStatus } =
      this.props.properties.propertyAnalysis;
    if (!selectedAnalysisPeriod) return;

    this.props.dispatch(
      fetchPropertyAnalysis({
        ...this.props.properties.params,
        analysis_period_id: selectedAnalysisPeriod.value,
        advisor_id: selectedAdvisor?.value ?? null,
        rcs_status_id: selectedRCSStatus?.value ?? null,
      }),
    );
  };

  setPropertyAnalysisParams = async (key, value) => {
    const { params } = this.props.properties;
    await this.props.dispatch(setPropertyAnalysisParams({ ...params, [key]: value }));
  };

  render() {
    const { analysisPeriodOptions, advisorOptions, rcsStatusOptions, searchValue, controls } =
      this.state;
    const { analysisData, responseMessage } = this.props.properties;
    const { selectedAnalysisPeriod, selectedAdvisor, selectedRCSStatus } =
      this.props.properties.propertyAnalysis;

    return (
      <>
        <div className="mt-1 ml-2">
          <Alert
            isOpen={!isNil(responseMessage)}
            color="danger"
            className="py-4 text-center"
            toggle={this.onAlertDismiss}
          >
            <strong className="align-middle">{responseMessage}</strong>
          </Alert>
          {!isNil(responseMessage) && <div className="border-corporate border-top mt-4" />}
        </div>
        <div className="mt-1">
          <Row className="justify-content-end align-items-end mb-1">
            <Col xs="3">
              <Label className="font-weight-bold mt-2">Advisor</Label>
              <ReactSelect
                // className="mx-2"
                options={advisorOptions}
                placeholder="Select Advisor"
                onChange={this.onAdvisorChange}
                value={selectedAdvisor}
                isClearable
              />
            </Col>
            <Col xs="2">
              <Label className="font-weight-bold mt-2">RCS Status</Label>
              <ReactSelect
                // className="mx-2"
                options={rcsStatusOptions}
                placeholder="Select Status"
                onChange={this.onRCSStatusChange}
                value={selectedRCSStatus}
                isClearable
              />
            </Col>
            <Col xs="2">
              <Label className="font-weight-bold mt-2">Analysis Period</Label>
              <ReactSelect
                // className="mx-2"
                options={analysisPeriodOptions}
                placeholder="Select Analysis Period"
                onChange={this.onAnalysisPeriodChange}
                value={selectedAnalysisPeriod}
              />
            </Col>
            <Col >
              <SearchToolbar
                rows={analysisData}
                searchValue={searchValue}
                onChange={this.onSearchChange}
                handleSearchChange={this.handleSearchChange}
                countLabel="properties"
                addNewButton={false}
              />
            </Col>
          </Row>
          <GenericLsv
            controls={this.addQuarterControls(
              controls,
              isEmpty(analysisData.rows) ? [] : analysisData.rows[0]?.quarters ?? [],
            )}
            emptyCaption="No properties found"
            iconName="house"
            rows={analysisData.rows}
            onClick={this.onEditProperty}
          />
        </div>
      </>
    );
  }
}

const mapStoreToProps = ({ admin, analysis_periods, properties }) => ({
  admin,
  analysis_periods,
  properties,
});

export default connect(mapStoreToProps)(PropertyAnalysis);
